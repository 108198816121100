.image-label{
    position: relative;
    width: 100%;
    /* height:100%; */
    background: #fff;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    display:flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 1px 7px rgba(105, 110, 232, 0.54);
    border-radius: 10px;
    flex-direction: column;
    gap: 15px;
    user-select: none;
    cursor: pointer;
    color: #207ed1;
    transition: all 1s;
    padding: 20px;
 }
 .image-label:hover{
    color: #18ac1c;
 }

 .image-label3{
   position: relative;
   width: 300px;
   height:100%;
   background: #fff;
   background-position: center;
   background-repeat: no-repeat;
   background-size: contain;
   display:flex;
   /* align-items: center; */
   /* justify-content: center; */
   box-shadow: 0px 1px 7px rgba(105, 110, 232, 0.54);
   border-radius: 10px;
   flex-direction: column;
   gap: 15px;
   user-select: none;
   cursor: pointer;
   color: #207ed1;
   transition: all 1s;
   padding: 20px;
}
.image-label3:hover{
   color: #18ac1c;
}

.box-shadown{
   box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);
   -webkit-box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);
   -moz-box-shadow: 10px 10px 5px 0px rgba(0,0,0,0.75);
}

.box {
   display: flex;
   width: 100%;
 }
 
 .one {
   flex: 1 1 auto;
   padding: 20px;
 }
 
 .two {
   flex: 1 1 auto;
   padding: 20px;
 }
 
 .three {
   flex: 1 1 auto;
   padding: 20px;
 }
.filterTableClass{
   margin-right: 66%;
}


